export default {
  'ad': () => import(/* webpackChunkName: "flag-ad" */ './FlagAd'),
  'ae': () => import(/* webpackChunkName: "flag-ae" */ './FlagAe'),
  'af': () => import(/* webpackChunkName: "flag-af" */ './FlagAf'),
  'ag': () => import(/* webpackChunkName: "flag-ag" */ './FlagAg'),
  'ai': () => import(/* webpackChunkName: "flag-ai" */ './FlagAi'),
  'al': () => import(/* webpackChunkName: "flag-al" */ './FlagAl'),
  'am': () => import(/* webpackChunkName: "flag-am" */ './FlagAm'),
  'an': () => import(/* webpackChunkName: "flag-an" */ './FlagAn'),
  'ao': () => import(/* webpackChunkName: "flag-ao" */ './FlagAo'),
  'aq': () => import(/* webpackChunkName: "flag-aq" */ './FlagAq'),
  'ar': () => import(/* webpackChunkName: "flag-ar" */ './FlagAr'),
  'as': () => import(/* webpackChunkName: "flag-as" */ './FlagAs'),
  'at': () => import(/* webpackChunkName: "flag-at" */ './FlagAt'),
  'au': () => import(/* webpackChunkName: "flag-au" */ './FlagAu'),
  'aw': () => import(/* webpackChunkName: "flag-aw" */ './FlagAw'),
  'ax': () => import(/* webpackChunkName: "flag-ax" */ './FlagAx'),
  'az': () => import(/* webpackChunkName: "flag-az" */ './FlagAz'),
  'ba': () => import(/* webpackChunkName: "flag-ba" */ './FlagBa'),
  'bb': () => import(/* webpackChunkName: "flag-bb" */ './FlagBb'),
  'bd': () => import(/* webpackChunkName: "flag-bd" */ './FlagBd'),
  'be': () => import(/* webpackChunkName: "flag-be" */ './FlagBe'),
  'bf': () => import(/* webpackChunkName: "flag-bf" */ './FlagBf'),
  'bg': () => import(/* webpackChunkName: "flag-bg" */ './FlagBg'),
  'bh': () => import(/* webpackChunkName: "flag-bh" */ './FlagBh'),
  'bi': () => import(/* webpackChunkName: "flag-bi" */ './FlagBi'),
  'bj': () => import(/* webpackChunkName: "flag-bj" */ './FlagBj'),
  'bl': () => import(/* webpackChunkName: "flag-bl" */ './FlagBl'),
  'bm': () => import(/* webpackChunkName: "flag-bm" */ './FlagBm'),
  'bn': () => import(/* webpackChunkName: "flag-bn" */ './FlagBn'),
  'bo': () => import(/* webpackChunkName: "flag-bo" */ './FlagBo'),
  'bq': () => import(/* webpackChunkName: "flag-bq" */ './FlagBq'),
  'br': () => import(/* webpackChunkName: "flag-br" */ './FlagBr'),
  'bs': () => import(/* webpackChunkName: "flag-bs" */ './FlagBs'),
  'bt': () => import(/* webpackChunkName: "flag-bt" */ './FlagBt'),
  'bv': () => import(/* webpackChunkName: "flag-bv" */ './FlagBv'),
  'bw': () => import(/* webpackChunkName: "flag-bw" */ './FlagBw'),
  'by': () => import(/* webpackChunkName: "flag-by" */ './FlagBy'),
  'bz': () => import(/* webpackChunkName: "flag-bz" */ './FlagBz'),
  'ca': () => import(/* webpackChunkName: "flag-ca" */ './FlagCa'),
  'cc': () => import(/* webpackChunkName: "flag-cc" */ './FlagCc'),
  'cd': () => import(/* webpackChunkName: "flag-cd" */ './FlagCd'),
  'cf': () => import(/* webpackChunkName: "flag-cf" */ './FlagCf'),
  'cg': () => import(/* webpackChunkName: "flag-cg" */ './FlagCg'),
  'ch': () => import(/* webpackChunkName: "flag-ch" */ './FlagCh'),
  'ci': () => import(/* webpackChunkName: "flag-ci" */ './FlagCi'),
  'ck': () => import(/* webpackChunkName: "flag-ck" */ './FlagCk'),
  'cl': () => import(/* webpackChunkName: "flag-cl" */ './FlagCl'),
  'cm': () => import(/* webpackChunkName: "flag-cm" */ './FlagCm'),
  'cn': () => import(/* webpackChunkName: "flag-cn" */ './FlagCn'),
  'co': () => import(/* webpackChunkName: "flag-co" */ './FlagCo'),
  'cr': () => import(/* webpackChunkName: "flag-cr" */ './FlagCr'),
  'cu': () => import(/* webpackChunkName: "flag-cu" */ './FlagCu'),
  'cv': () => import(/* webpackChunkName: "flag-cv" */ './FlagCv'),
  'cw': () => import(/* webpackChunkName: "flag-cw" */ './FlagCw'),
  'cx': () => import(/* webpackChunkName: "flag-cx" */ './FlagCx'),
  'cy': () => import(/* webpackChunkName: "flag-cy" */ './FlagCy'),
  'cz': () => import(/* webpackChunkName: "flag-cz" */ './FlagCz'),
  'de': () => import(/* webpackChunkName: "flag-de" */ './FlagDe'),
  'dj': () => import(/* webpackChunkName: "flag-dj" */ './FlagDj'),
  'dk': () => import(/* webpackChunkName: "flag-dk" */ './FlagDk'),
  'dm': () => import(/* webpackChunkName: "flag-dm" */ './FlagDm'),
  'do': () => import(/* webpackChunkName: "flag-do" */ './FlagDo'),
  'dz': () => import(/* webpackChunkName: "flag-dz" */ './FlagDz'),
  'ec': () => import(/* webpackChunkName: "flag-ec" */ './FlagEc'),
  'ee': () => import(/* webpackChunkName: "flag-ee" */ './FlagEe'),
  'eg': () => import(/* webpackChunkName: "flag-eg" */ './FlagEg'),
  'eh': () => import(/* webpackChunkName: "flag-eh" */ './FlagEh'),
  'er': () => import(/* webpackChunkName: "flag-er" */ './FlagEr'),
  'es': () => import(/* webpackChunkName: "flag-es" */ './FlagEs'),
  'et': () => import(/* webpackChunkName: "flag-et" */ './FlagEt'),
  'eu': () => import(/* webpackChunkName: "flag-eu" */ './FlagEu'),
  'fi': () => import(/* webpackChunkName: "flag-fi" */ './FlagFi'),
  'fj': () => import(/* webpackChunkName: "flag-fj" */ './FlagFj'),
  'fk': () => import(/* webpackChunkName: "flag-fk" */ './FlagFk'),
  'fm': () => import(/* webpackChunkName: "flag-fm" */ './FlagFm'),
  'fo': () => import(/* webpackChunkName: "flag-fo" */ './FlagFo'),
  'fr': () => import(/* webpackChunkName: "flag-fr" */ './FlagFr'),
  'ga': () => import(/* webpackChunkName: "flag-ga" */ './FlagGa'),
  'gb': () => import(/* webpackChunkName: "flag-gb" */ './FlagGb'),
  'gd': () => import(/* webpackChunkName: "flag-gd" */ './FlagGd'),
  'ge': () => import(/* webpackChunkName: "flag-ge" */ './FlagGe'),
  'gf': () => import(/* webpackChunkName: "flag-gf" */ './FlagGf'),
  'gg': () => import(/* webpackChunkName: "flag-gg" */ './FlagGg'),
  'gh': () => import(/* webpackChunkName: "flag-gh" */ './FlagGh'),
  'gi': () => import(/* webpackChunkName: "flag-gi" */ './FlagGi'),
  'gl': () => import(/* webpackChunkName: "flag-gl" */ './FlagGl'),
  'gm': () => import(/* webpackChunkName: "flag-gm" */ './FlagGm'),
  'gn': () => import(/* webpackChunkName: "flag-gn" */ './FlagGn'),
  'gp': () => import(/* webpackChunkName: "flag-gp" */ './FlagGp'),
  'gq': () => import(/* webpackChunkName: "flag-gq" */ './FlagGq'),
  'gr': () => import(/* webpackChunkName: "flag-gr" */ './FlagGr'),
  'gs': () => import(/* webpackChunkName: "flag-gs" */ './FlagGs'),
  'gt': () => import(/* webpackChunkName: "flag-gt" */ './FlagGt'),
  'gu': () => import(/* webpackChunkName: "flag-gu" */ './FlagGu'),
  'gw': () => import(/* webpackChunkName: "flag-gw" */ './FlagGw'),
  'gy': () => import(/* webpackChunkName: "flag-gy" */ './FlagGy'),
  'hk': () => import(/* webpackChunkName: "flag-hk" */ './FlagHk'),
  'hm': () => import(/* webpackChunkName: "flag-hm" */ './FlagHm'),
  'hn': () => import(/* webpackChunkName: "flag-hn" */ './FlagHn'),
  'hr': () => import(/* webpackChunkName: "flag-hr" */ './FlagHr'),
  'ht': () => import(/* webpackChunkName: "flag-ht" */ './FlagHt'),
  'hu': () => import(/* webpackChunkName: "flag-hu" */ './FlagHu'),
  'id': () => import(/* webpackChunkName: "flag-id" */ './FlagId'),
  'ie': () => import(/* webpackChunkName: "flag-ie" */ './FlagIe'),
  'il': () => import(/* webpackChunkName: "flag-il" */ './FlagIl'),
  'im': () => import(/* webpackChunkName: "flag-im" */ './FlagIm'),
  'in': () => import(/* webpackChunkName: "flag-in" */ './FlagIn'),
  'io': () => import(/* webpackChunkName: "flag-io" */ './FlagIo'),
  'iq': () => import(/* webpackChunkName: "flag-iq" */ './FlagIq'),
  'ir': () => import(/* webpackChunkName: "flag-ir" */ './FlagIr'),
  'is': () => import(/* webpackChunkName: "flag-is" */ './FlagIs'),
  'it': () => import(/* webpackChunkName: "flag-it" */ './FlagIt'),
  'je': () => import(/* webpackChunkName: "flag-je" */ './FlagJe'),
  'jm': () => import(/* webpackChunkName: "flag-jm" */ './FlagJm'),
  'jo': () => import(/* webpackChunkName: "flag-jo" */ './FlagJo'),
  'jp': () => import(/* webpackChunkName: "flag-jp" */ './FlagJp'),
  'ke': () => import(/* webpackChunkName: "flag-ke" */ './FlagKe'),
  'kg': () => import(/* webpackChunkName: "flag-kg" */ './FlagKg'),
  'kh': () => import(/* webpackChunkName: "flag-kh" */ './FlagKh'),
  'ki': () => import(/* webpackChunkName: "flag-ki" */ './FlagKi'),
  'km': () => import(/* webpackChunkName: "flag-km" */ './FlagKm'),
  'kn': () => import(/* webpackChunkName: "flag-kn" */ './FlagKn'),
  'kp': () => import(/* webpackChunkName: "flag-kp" */ './FlagKp'),
  'kr': () => import(/* webpackChunkName: "flag-kr" */ './FlagKr'),
  'kw': () => import(/* webpackChunkName: "flag-kw" */ './FlagKw'),
  'ky': () => import(/* webpackChunkName: "flag-ky" */ './FlagKy'),
  'kz': () => import(/* webpackChunkName: "flag-kz" */ './FlagKz'),
  'la': () => import(/* webpackChunkName: "flag-la" */ './FlagLa'),
  'lb': () => import(/* webpackChunkName: "flag-lb" */ './FlagLb'),
  'lc': () => import(/* webpackChunkName: "flag-lc" */ './FlagLc'),
  'li': () => import(/* webpackChunkName: "flag-li" */ './FlagLi'),
  'lk': () => import(/* webpackChunkName: "flag-lk" */ './FlagLk'),
  'lr': () => import(/* webpackChunkName: "flag-lr" */ './FlagLr'),
  'ls': () => import(/* webpackChunkName: "flag-ls" */ './FlagLs'),
  'lt': () => import(/* webpackChunkName: "flag-lt" */ './FlagLt'),
  'lu': () => import(/* webpackChunkName: "flag-lu" */ './FlagLu'),
  'lv': () => import(/* webpackChunkName: "flag-lv" */ './FlagLv'),
  'ly': () => import(/* webpackChunkName: "flag-ly" */ './FlagLy'),
  'ma': () => import(/* webpackChunkName: "flag-ma" */ './FlagMa'),
  'mc': () => import(/* webpackChunkName: "flag-mc" */ './FlagMc'),
  'md': () => import(/* webpackChunkName: "flag-md" */ './FlagMd'),
  'me': () => import(/* webpackChunkName: "flag-me" */ './FlagMe'),
  'mf': () => import(/* webpackChunkName: "flag-mf" */ './FlagMf'),
  'mg': () => import(/* webpackChunkName: "flag-mg" */ './FlagMg'),
  'mh': () => import(/* webpackChunkName: "flag-mh" */ './FlagMh'),
  'mk': () => import(/* webpackChunkName: "flag-mk" */ './FlagMk'),
  'ml': () => import(/* webpackChunkName: "flag-ml" */ './FlagMl'),
  'mm': () => import(/* webpackChunkName: "flag-mm" */ './FlagMm'),
  'mn': () => import(/* webpackChunkName: "flag-mn" */ './FlagMn'),
  'mo': () => import(/* webpackChunkName: "flag-mo" */ './FlagMo'),
  'mp': () => import(/* webpackChunkName: "flag-mp" */ './FlagMp'),
  'mq': () => import(/* webpackChunkName: "flag-mq" */ './FlagMq'),
  'mr': () => import(/* webpackChunkName: "flag-mr" */ './FlagMr'),
  'ms': () => import(/* webpackChunkName: "flag-ms" */ './FlagMs'),
  'mt': () => import(/* webpackChunkName: "flag-mt" */ './FlagMt'),
  'mu': () => import(/* webpackChunkName: "flag-mu" */ './FlagMu'),
  'mv': () => import(/* webpackChunkName: "flag-mv" */ './FlagMv'),
  'mw': () => import(/* webpackChunkName: "flag-mw" */ './FlagMw'),
  'mx': () => import(/* webpackChunkName: "flag-mx" */ './FlagMx'),
  'my': () => import(/* webpackChunkName: "flag-my" */ './FlagMy'),
  'mz': () => import(/* webpackChunkName: "flag-mz" */ './FlagMz'),
  'na': () => import(/* webpackChunkName: "flag-na" */ './FlagNa'),
  'nc': () => import(/* webpackChunkName: "flag-nc" */ './FlagNc'),
  'ne': () => import(/* webpackChunkName: "flag-ne" */ './FlagNe'),
  'nf': () => import(/* webpackChunkName: "flag-nf" */ './FlagNf'),
  'ng': () => import(/* webpackChunkName: "flag-ng" */ './FlagNg'),
  'ni': () => import(/* webpackChunkName: "flag-ni" */ './FlagNi'),
  'nl': () => import(/* webpackChunkName: "flag-nl" */ './FlagNl'),
  'no': () => import(/* webpackChunkName: "flag-no" */ './FlagNo'),
  'np': () => import(/* webpackChunkName: "flag-np" */ './FlagNp'),
  'nr': () => import(/* webpackChunkName: "flag-nr" */ './FlagNr'),
  'nu': () => import(/* webpackChunkName: "flag-nu" */ './FlagNu'),
  'nz': () => import(/* webpackChunkName: "flag-nz" */ './FlagNz'),
  'om': () => import(/* webpackChunkName: "flag-om" */ './FlagOm'),
  'pa': () => import(/* webpackChunkName: "flag-pa" */ './FlagPa'),
  'pe': () => import(/* webpackChunkName: "flag-pe" */ './FlagPe'),
  'pf': () => import(/* webpackChunkName: "flag-pf" */ './FlagPf'),
  'pg': () => import(/* webpackChunkName: "flag-pg" */ './FlagPg'),
  'ph': () => import(/* webpackChunkName: "flag-ph" */ './FlagPh'),
  'pk': () => import(/* webpackChunkName: "flag-pk" */ './FlagPk'),
  'pl': () => import(/* webpackChunkName: "flag-pl" */ './FlagPl'),
  'pm': () => import(/* webpackChunkName: "flag-pm" */ './FlagPm'),
  'pn': () => import(/* webpackChunkName: "flag-pn" */ './FlagPn'),
  'pr': () => import(/* webpackChunkName: "flag-pr" */ './FlagPr'),
  'ps': () => import(/* webpackChunkName: "flag-ps" */ './FlagPs'),
  'pt': () => import(/* webpackChunkName: "flag-pt" */ './FlagPt'),
  'pw': () => import(/* webpackChunkName: "flag-pw" */ './FlagPw'),
  'py': () => import(/* webpackChunkName: "flag-py" */ './FlagPy'),
  'qa': () => import(/* webpackChunkName: "flag-qa" */ './FlagQa'),
  're': () => import(/* webpackChunkName: "flag-re" */ './FlagRe'),
  'ro': () => import(/* webpackChunkName: "flag-ro" */ './FlagRo'),
  'rs': () => import(/* webpackChunkName: "flag-rs" */ './FlagRs'),
  'ru': () => import(/* webpackChunkName: "flag-ru" */ './FlagRu'),
  'rw': () => import(/* webpackChunkName: "flag-rw" */ './FlagRw'),
  'sa': () => import(/* webpackChunkName: "flag-sa" */ './FlagSa'),
  'sb': () => import(/* webpackChunkName: "flag-sb" */ './FlagSb'),
  'sc': () => import(/* webpackChunkName: "flag-sc" */ './FlagSc'),
  'sd': () => import(/* webpackChunkName: "flag-sd" */ './FlagSd'),
  'se': () => import(/* webpackChunkName: "flag-se" */ './FlagSe'),
  'sg': () => import(/* webpackChunkName: "flag-sg" */ './FlagSg'),
  'sh': () => import(/* webpackChunkName: "flag-sh" */ './FlagSh'),
  'si': () => import(/* webpackChunkName: "flag-si" */ './FlagSi'),
  'sj': () => import(/* webpackChunkName: "flag-sj" */ './FlagSj'),
  'sk': () => import(/* webpackChunkName: "flag-sk" */ './FlagSk'),
  'sl': () => import(/* webpackChunkName: "flag-sl" */ './FlagSl'),
  'sm': () => import(/* webpackChunkName: "flag-sm" */ './FlagSm'),
  'sn': () => import(/* webpackChunkName: "flag-sn" */ './FlagSn'),
  'so': () => import(/* webpackChunkName: "flag-so" */ './FlagSo'),
  'sr': () => import(/* webpackChunkName: "flag-sr" */ './FlagSr'),
  'ss': () => import(/* webpackChunkName: "flag-ss" */ './FlagSs'),
  'st': () => import(/* webpackChunkName: "flag-st" */ './FlagSt'),
  'sv': () => import(/* webpackChunkName: "flag-sv" */ './FlagSv'),
  'sx': () => import(/* webpackChunkName: "flag-sx" */ './FlagSx'),
  'sy': () => import(/* webpackChunkName: "flag-sy" */ './FlagSy'),
  'sz': () => import(/* webpackChunkName: "flag-sz" */ './FlagSz'),
  'tc': () => import(/* webpackChunkName: "flag-tc" */ './FlagTc'),
  'td': () => import(/* webpackChunkName: "flag-td" */ './FlagTd'),
  'tf': () => import(/* webpackChunkName: "flag-tf" */ './FlagTf'),
  'tg': () => import(/* webpackChunkName: "flag-tg" */ './FlagTg'),
  'th': () => import(/* webpackChunkName: "flag-th" */ './FlagTh'),
  'tj': () => import(/* webpackChunkName: "flag-tj" */ './FlagTj'),
  'tk': () => import(/* webpackChunkName: "flag-tk" */ './FlagTk'),
  'tl': () => import(/* webpackChunkName: "flag-tl" */ './FlagTl'),
  'tm': () => import(/* webpackChunkName: "flag-tm" */ './FlagTm'),
  'tn': () => import(/* webpackChunkName: "flag-tn" */ './FlagTn'),
  'to': () => import(/* webpackChunkName: "flag-to" */ './FlagTo'),
  'tr': () => import(/* webpackChunkName: "flag-tr" */ './FlagTr'),
  'tt': () => import(/* webpackChunkName: "flag-tt" */ './FlagTt'),
  'tv': () => import(/* webpackChunkName: "flag-tv" */ './FlagTv'),
  'tw': () => import(/* webpackChunkName: "flag-tw" */ './FlagTw'),
  'tz': () => import(/* webpackChunkName: "flag-tz" */ './FlagTz'),
  'ua': () => import(/* webpackChunkName: "flag-ua" */ './FlagUa'),
  'ug': () => import(/* webpackChunkName: "flag-ug" */ './FlagUg'),
  'um': () => import(/* webpackChunkName: "flag-um" */ './FlagUm'),
  'us': () => import(/* webpackChunkName: "flag-us" */ './FlagUs'),
  'uy': () => import(/* webpackChunkName: "flag-uy" */ './FlagUy'),
  'uz': () => import(/* webpackChunkName: "flag-uz" */ './FlagUz'),
  'va': () => import(/* webpackChunkName: "flag-va" */ './FlagVa'),
  'vc': () => import(/* webpackChunkName: "flag-vc" */ './FlagVc'),
  've': () => import(/* webpackChunkName: "flag-ve" */ './FlagVe'),
  'vg': () => import(/* webpackChunkName: "flag-vg" */ './FlagVg'),
  'vi': () => import(/* webpackChunkName: "flag-vi" */ './FlagVi'),
  'vn': () => import(/* webpackChunkName: "flag-vn" */ './FlagVn'),
  'vu': () => import(/* webpackChunkName: "flag-vu" */ './FlagVu'),
  'wf': () => import(/* webpackChunkName: "flag-wf" */ './FlagWf'),
  'ws': () => import(/* webpackChunkName: "flag-ws" */ './FlagWs'),
  'xk': () => import(/* webpackChunkName: "flag-xk" */ './FlagXk'),
  'ye': () => import(/* webpackChunkName: "flag-ye" */ './FlagYe'),
  'yt': () => import(/* webpackChunkName: "flag-yt" */ './FlagYt'),
  'za': () => import(/* webpackChunkName: "flag-za" */ './FlagZa'),
  'zm': () => import(/* webpackChunkName: "flag-zm" */ './FlagZm'),
  'zw': () => import(/* webpackChunkName: "flag-zw" */ './FlagZw'),
};
